<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="20">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="课程名称" style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-cascader
                :changeOnSelect="true"
                placeholder="归属目录"
                v-model="form.indexName"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'name',
                  children: 'children',
                }"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="课程状态" style="width: 150px">
                <a-select-option
                  v-for="item in [
                    {
                      name: '已上架',
                      value: 1,
                    },
                    {
                      name: '已下架',
                      value: 0,
                    },
                         {
                      name: '处理中',
                      value: 2,
                    },
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button
                type="primary"
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
              >创建课程</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="课程名称" data-index="name"></a-table-column>

        <a-table-column title="归属目录" data-index="indexName" align="center"></a-table-column>
        <a-table-column title="排序值" data-index="top" width="80px" align="center"></a-table-column>
        <a-table-column title="授课者" data-index="creator" width="100px" align="center"></a-table-column>
        <a-table-column title="更新时间" data-index="createAt" width="160px" align="center"></a-table-column>
        <a-table-column title="状态" width="100px" align="center">
          <template slot-scope="text">
            <span>
              <a-badge v-if="text.status === 0" color="red" text="已下架" />
              <a-badge v-if="text.status === 1" color="green" text="已上架" />
              <a-tooltip v-if="text.status === 2" title="课程正在后台处理中，不能上架或下架，请稍后点击重置按钮刷新">
                <a-badge status="processing" text="处理中" />
              </a-tooltip>
            </span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <!-- <a href="#" @click.prevent="edit(text)">编辑</a> -->
              <a
                href="#"
                v-if="$getPermission($route.path + '/export')"
                @click.prevent="exportFile(text)"
              >导出</a>
              <a
                v-if="text.status === 1 || text.status === 0"
                href="#"
                class="danger"
                @click.prevent="ban(text)"
              >{{ text.status === 1 ? '下架' : '上架' }}</a>
              <!-- <a href="#" class="danger" @click.prevent="remove(text)">删除</a> -->
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import study from "@/mixins/study";
import download from '@/api/download'
function fetchList(params) {
  return request({
    url: "/office-service/study/course/list",
    params
  });
}

function changeStatus(params) {
  return request({
    url: "/office-service/study/course/status",
    method: "post",
    params
  });
}

function exportDoc(params) {
  return download({
    url: "/office-service/study/user/course/" + params + "/export",
  });
}

// function del(data) {
//   return request({
//     url: "/office-service/study/course/del",
//     method: "post",
//     data
//   });
// }

export default {
  mixins: [watermark, study],

  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    exportFile(text) {
      exportDoc(text.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',text.name + '.xlsx');
        document.body.appendChild(link);
        link.click();
      });
    },
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        indexName: Array.isArray(this.form.indexName)
          ? this.form.indexName[this.form.indexName.length - 1]
          : undefined
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.getList();
    },

    // edit(text) {
    //   this.$router.push(this.$route.path + "/edit?id=" + text.id);
    // },

    ban(text) {
      const that = this;
      this.$confirm({
        title: "确定要继续吗？",
        onOk() {
          const hide = that.$message.loading("操作中...");
          changeStatus({
            id: text.id,
            status: text.status === 1 ? 0 : 1
          })
            .then(() => {
              that.getList();
            })
            .finally(() => {
              hide();
            });
        }
      });
    }
    // remove(text) {
    //   const that = this;
    //   this.$confirm({
    //     title: "确定要继续吗？",
    //     onOk() {
    //       const hide = that.$message.loading("操作中...");
    //       del({
    //         id: text.id
    //       })
    //         .then(() => {
    //           that.getList();
    //         })
    //         .finally(() => {
    //           hide();
    //         });
    //     }
    //   });
    // }
  }
};
</script>
